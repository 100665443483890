import React from 'react'

import './portfolio.css'

import IMG1 from '../../assets/section_portfolio/drgrillzbcn.png'
import IMG2 from '../../assets/section_portfolio/institut-bellarosa.png'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Creation of the website for the company : Dr Grillz BCN',
    website: 'https://drgrillzbcn.com/'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Creation of the website for the company : Bellarosa',
    website: 'https://institut-bellarosa.com/'
  }
]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Works</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({ id, image, title, github, demo, website }) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  {github && <a href={github} className='btn' target='_blank' rel="noreferrer">Github</a>}
                  {demo && <a href={demo} className='btn btn-primary' target='_blank' rel="noreferrer">Live Demo</a>}
                  {website && <a href={website} className='btn btn-primary' target='_blank' rel="noreferrer">Go to the website</a>}
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio